
import MyTable from "@/components/MyTable/index.vue";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
} from "vue";
export default defineComponent({
  name: "",
  components: { MyTable },
  setup() {
    const data = reactive({
      Title: [
        { label: "序号", value: "equipment" },
        { label: "账号名称", value: "content" },
        { label: "状态", value: "type" },
      ],
      Arr: [
        {
          equipment: "1",
          content: "早班班组账号",
          type: "启用",
        },
      ],
      tltleName: "操作",
      operateList: [{ name: "禁用", color: "#00FFFF" }],
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
    });
    return {
      ...toRefs(data),
    };
  },
});
