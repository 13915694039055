
import DatePicker from "@/components/DatePicker/index.vue";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
} from "vue";
export default defineComponent({
  components: { DatePicker },
  name: "",
  setup() {
    const data = reactive({
      list: [
        {
          id: 1,
          name: "早班",
          personnel: [
            {
              id: 1,
              name: "苏强",
            },
            {
              id: 2,
              name: "陈曌",
            },
          ],
          personnelName: "人员",
          remarkName: "备注",
          remark: "",
        },
        {
          id: 2,
          name: "中班",
          personnel: [
            {
              id: 1,
              name: "翁文超",
            },
            {
              id: 2,
              name: "苏强",
            },
          ],
          personnelName: "人员",
          remarkName: "备注",
          remark: "",
        },
        {
          id: 3,
          name: "晚班",
          personnel: [
            {
              id: 1,
              name: "苏强",
            },
            {
              id: 2,
              name: "翁文超",
            },
          ],
          personnelName: "人员",
          remarkName: "备注",
          remark: "",
        },
      ],
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
    });
    return {
      ...toRefs(data),
    };
  },
});
