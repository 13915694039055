
import MyTable from "@/components/MyTable/index.vue";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
} from "vue";
export default defineComponent({
  name: "",
  components: { MyTable },
  setup() {
    const data = reactive({
      widths: "120",
      Title: [
        { label: "序号", value: "equipment" },
        { label: "员工姓名", value: "content" },
      ],
      Arr: [
        {
          equipment: "1",
          content: "陈曌",
        },
      ],
      tltleName: "操作",
      operateList: [{ name: "删除", color: "#00FFFF" }],
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
    });
    return {
      ...toRefs(data),
    };
  },
});
