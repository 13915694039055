
import Account from "./components/Account.vue";
import Staff from "./components/Staff.vue";
import Classes from "./components/Classes.vue";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
} from "vue";
export default defineComponent({
  name: "",
  components: { Account, Staff, Classes },
  setup() {
    const data = reactive({
      list: [
        { id: 1, name: "账号管理", active: true },
        { id: 2, name: "人员管理", active: false },
        { id: 3, name: "班次管理", active: false },
      ],
      IdShow: 1,
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
    });
    const getCutMenu = (Data: any) => {
      data.IdShow = Data.id;
      data.list.filter((item) =>
        item.id == Data.id ? (item.active = true) : (item.active = false)
      );
    };
    return {
      ...toRefs(data),
      getCutMenu,
    };
  },
});
